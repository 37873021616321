import React, { useContext } from 'react';
import { SelectedContext } from '../../utils/selectedContext';
import './buttons.css';
import { Link } from 'react-router-dom';

export default function NavButton({ type, page, onClick }) {
    const { selectedPage, setSelectedPage } = useContext(SelectedContext);

    function handleClick() {
        if (selectedPage !== page) {
            setSelectedPage(page);
        }
        if (onClick) {
            onClick();
        }
    }

    return (
        <Link className='navButton' to={`/${page}`} onClick={handleClick}>
            {type}
        </Link>
    );
}
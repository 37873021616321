import React, { useState, useEffect } from 'react';
import { validateName, validateEmail, validateMessage } from '../../utils/helpers';
import './ContactForm.css';

export default function ContactForm() {
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [invalid, setInvalid] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  async function handleSubmit(e) {
    e.preventDefault();
    setInvalid('');
    setError('');
    setSuccess('');
  
    const formSubmit = { name, email, message };
  
    const isNameValid = validateName(formSubmit.name);
    const isEmailValid = validateEmail(formSubmit.email);
    const isMessageValid = validateMessage(formSubmit.message);
    
    console.log(isNameValid);
    console.log(isEmailValid);
    console.log(isMessageValid);
  
    if (isNameValid && isEmailValid && isMessageValid) {
      try {
        const response = await fetch('/cgi-bin/send_email_secure.pl', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formSubmit)
        });
  
        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.includes('application/json')) {
          const result = await response.json();
          if (response.ok) {
            setSuccess(result.message);
          } else {
            setError(result.message);
          }
        } else {
          const errorText = await response.text();
          console.error('Error:', errorText);
          setError('Email sending failed. Server returned an unexpected response.');
        }
      } catch (error) {
        console.error('Error:', error);
        setError('Email sending failed.');
      }
    } else {
      let invalidMsg = '';
      if (!isNameValid) invalidMsg += 'Vennligst oppgi et gyldig navn. ';
      if (!isEmailValid) invalidMsg += 'Vennligst oppgi en gyldig e-postadresse. ';
      if (!isMessageValid) invalidMsg += 'Vennligst oppgi en gyldig melding';
      if (isMessageValid === 'string') invalidMsg += 'Melding kan ikke være tom.';
      setInvalid(invalidMsg.trim());
    }
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    setCharCount(e.target.value.length);
  };

  return (
        <form className="contact-form" onSubmit={handleSubmit} noValidate>
          <div className='contact-form__name-email-wrapper'>
            <input
              type="name"
              id="name"
              name="message"
               maxLength="200"
              placeholder='Navn'
              value={name}
              onChange={(e) => setName(e.target.value)}
              onInvalid={(e) => e.preventDefault()} 
            />
            <input
              type="email"
              id="email"
              name="email"
              maxLength="200"
              placeholder='Email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onInvalid={(e) => e.preventDefault()} 
            />
          </div>
          <textarea
            id="message"
            name="message"
            placeholder='Melding...'
            maxLength="1000"
            value={message}
            onChange={handleMessageChange}
            onInvalid={(e) => e.preventDefault()} 
          />
          <div className="feedback-content">
            {invalid && <div className="invalid-input-message">{invalid}</div>}
            <div></div>
            <span className='character-count'>{charCount} / 1000</span> 
            {charCount > 900 && charCount <= 1000 && <span>(Nærmer seg grensen)</span>}
          </div>
          <button className='contact-form__button' type="submit">Send</button>
          {success && <div className="success-message">{success}</div>}
          {error && <div className="error-message">{error}</div>}
        </form>
  );
}
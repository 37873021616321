import React from 'react'
import './employeeCard.css'
import janImg from "../../../assets/portraits/jan_ny.jpg"
import victorImg from "../../../assets/portraits/victor_ny.jpg"
import  kevinImg from "../../../assets/portraits/kevin_ny.jpg"
import  sondreImg from "../../../assets/portraits/sondre_ny.jpg"
import tobiasImg from "../../../assets/portraits/tobias_ny.png"

const employees = [
  {
    name: 'Jan Petter Andersen',
    img: janImg,
    position: 'Daglig Leder',
    number: '99998888',
    email: 'Jan@xinit.no',
  },
  {
    name: 'Victor Barstad',
    img: victorImg,
    position: 'Prosjekt utvikler',
    number: '99998888',
    email: 'Victor@xinit.no',
  },
  {
    name: 'Kevin Enger Morønning',
    img: kevinImg,
    position: 'Prosjekt utvikler',
    number: '99998888',
    email: 'Kevin@xinit.no',
  },
  {
    name: 'Sondre Jaros',
    img: sondreImg,
    position: 'Backend utvikler',
    number: '99998888',
    email: 'Sondre@xinit.no',
  },
  {
    name: 'Tobias Tønnessen',
    img: tobiasImg,
    position: 'Frontend utvikler',
    number: '99998888',
    email: 'Tobias@xinit.no',
  }
];

export default function EmployeeCard() {
  return (
    <div className="employee-card_list">
      {employees.map((employee, index) => (
        <div className="employee-card background-wrapper" key={index}>
          <img
            src={employee.img}
            alt={employee.name}
            className="employee-card_photo"
          />
            <div className="employee-card_title-wrapper">
              <h4 className="employee-card_name">{employee.name}</h4>
              <p className="employee-card_role">{employee.position}</p>
              <a className='employee-card_email' href={`mailto:${employee.email}`}>
                {employee.email}
              </a>
          </div>
        </div>
      ))}
    </div>
  );
}
import React from "react"
import ContactForm from "../components/submitforms/ContactForm"
import ContactIntro from "../components/introSections/contactIntro"
import RotatingSquare from "../effects/blurEffectBigBlob"

export default function Contact(){

  return (
    <div className='main'>
      <ContactIntro/>
      <ContactForm/>
    </div>
  )
}
import React from 'react';
import EmployeeCard from '../components/cards/employeeCard/employeeCard';
import Introduction from '../components/introSections/companyIntro';
import RotatingSquare from '../effects/blurEffectBigBlob';


export default function About() {


  return (
    <div className='main'>
     
    </div>
  );
}
import React from 'react'
import './components.css'
import NavButton from './buttons/navButton';
import ContactButton from './buttons/contact-button';

export default function Footer() {
  return (
    <footer>
      <div className='footer-wrapper'>
        <div className='footer-left-wrapper'>
          <div className='footer-logo-wrapper zoom-on-hover-5'>
            <a href="https://xinit.no/" className='footer-company-name'>Xinit</a>
          </div>
          <p className='footer-copyright-text'>Copyright Xinit.no</p>
        </div>
         
        <div className='footer-right-wrapper'>
          <NavButton type='Xamin App' page='product' />
          <ContactButton type='Kontakt' page='contact' />
        </div>
      </div>
    </footer>
  );
}
// helpers.js

/**
 * Validates a name to ensure it contains only letters, spaces, hyphens, and apostrophes.
 * @param {string} name - The name to validate.
 * @returns {boolean} - True if the name is valid, false otherwise.
 */
export function validateName(name) {
    const regex = /^[a-zA-ZæøåÆØÅ\s'-]+$/;
    return regex.test(name);
}

/**
 * Validates an email to ensure it follows a standard email format.
 * @param {string} email - The email to validate.
 * @returns {boolean} - True if the email is valid, false otherwise.
 */
export function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
}

/**
 * Validates a message to ensure it contains only letters, numbers, spaces, and common punctuation.
 * @param {string} message - The message to validate.
 * @returns {boolean} - True if the message is valid, false otherwise.
 */
export function validateMessage(message) {
    if ('' === message) {
        return ('Message cannot be empty');
    } else {
        const regex = /^[\wæøåÆØÅ\s,.\@!?'-]+$/;
        return regex.test(message);
    }
}









import React, { useContext } from 'react'
import "./buttons.css"
import { Link } from 'react-router-dom'


export default function ContactButton({ type, page }) {

  return (
    <Link className='contact-button' to={`/${page}`}>
      {type}
    </Link>
  );
}
import React, {useEffect} from 'react'
import "./products.css"
import hammer from "../../assets/hammer.png"
import handshield from '../../assets/handshield.png'
import tilsdandvurdering from "../../assets/tilsdandvurdering.png"

export default function XaminProduct() {

  useEffect(() => {
    const handleScroll = () => {
      const hiddenProductPerkElements = document.querySelectorAll('.hidden-left-product-perk, .hidden-right-product-perk');
      const hiddenProductHeaderElement = document.querySelectorAll('.hidden-left-product-header');
      const screenWidth = window.innerWidth;

      const triggerPointHeaderElement = window.innerHeight - (window.innerHeight * -0.9);
      const triggerPointPerkElementsMobile = window.innerHeight - (window.innerHeight * -0.4);
      const triggerPointPerkElementsPC = window.innerHeight - (window.innerHeight * 0.2);
      const triggerPointPerkElements = screenWidth <= 780 ? triggerPointPerkElementsMobile : triggerPointPerkElementsPC;

      hiddenProductHeaderElement.forEach((el) => {
        const rect = el.getBoundingClientRect();
        if (rect.bottom < triggerPointHeaderElement && rect.top > 0) {
          el.classList.add('show');
          el.style.top = '0';
        }
      });

      hiddenProductPerkElements.forEach((el) => {
        const rect = el.getBoundingClientRect();
        if (rect.bottom < triggerPointPerkElements && rect.top > 0) {
          el.classList.add('show');
          el.style.top = '0';
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Trigger on mount

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>
      <div className='product-header__wrapper hidden-left-product-header'>
        <div className='product-header__text-content'>
          <h1 className='product-header__title'>Xamin App</h1>
          <p className='product-header__text'>Et toppmoderne system for bygningssakskyndige som ønsker det beste for seg selv og sine kunder, og mindre bekymringer i en travel hverdag.</p>
        </div>
        <img className='product-header__img' src="xaminhand2.png" alt='Product header picture'/>
      </div>

      <div className='product-perk__list'>
      <div className='product-perk__wrapper hidden-left-product-perk'>
        <div className='product-perk__info-wrapper'>
            <h2 className='product-perk__title'>Basert på de nye kravene</h2>
            <p className='product-perk__description'>Vår app for bygningssakkyndige er utviklet for å effektivisere og forenkle arbeidet med tilstandsrapporter, i tråd med NS3600-standarden og forskriftene for tryggere bolighandel.</p>
        </div>
        <div className='product-perk__img-align'>
        <img className='product-perk__img' src={hammer} alt='Product picture ' loading="lazy"/>
        </div>
      </div>

      <div className='product-perk__wrapper hidden-right-product-perk'>
      <div className='product-perk__img-align show-on-big-screen'>
        <img className='product-perk__img' src={tilsdandvurdering} alt='Product picture' loading="lazy"/>
        </div>
        <div className='product-perk__info-wrapper'>
          <h2 className='product-perk__title'>Effektiv og nøyaktig tilstandsvurdering</h2>
          <p className='product-perk__description'>Med et intuitivt grensesnitt og avanserte funksjoner gir programvaren deg muligheten til å dokumentere, vurdere og rapportere bygningers tilstand med høy grad av nøyaktighet og faglig integritet.</p>
        </div>
        <div className='product-perk__img-align show-on-small-screen'>
        <img className='product-perk__img show-on-small-screen' src={tilsdandvurdering} alt='Product picture' loading="lazy"/>
        </div>  
      </div>  

      <div className='product-perk__wrapper hidden-left-product-perk'>
        <div className='product-perk__info-wrapper'>
          <h2 className='product-perk__title'>Trygghet gjennom kvalitet</h2>
          <p className='product-perk__description'>Appen leder deg gjennom hele prosessen, fra inspeksjon til ferdig rapport, og sikrer at alle krav og standarder blir ivaretatt. Dette gir deg ikke bare en tryggere og mer effektiv arbeidshverdag, men også trygghet for at dine rapporter møter de høyeste kravene til kvalitet og nøyaktighet.</p>
        </div>
        <div className='product-perk__img-align'>
        <img className='product-perk__img' src={handshield} alt='Product picture' loading="lazy"/>
        </div>
      </div>
      </div>
    </>
  );
}


import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import SelectedContextProvider from './utils/selectedContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

  <React.StrictMode>
    <SelectedContextProvider>
      <Router>
        <App />
      </Router>
    </SelectedContextProvider>
  </React.StrictMode>
 
);

// If you want to measure performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
reportWebVitals();
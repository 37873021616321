import React from 'react';
import './Introduction.css';
import heroImg from '../../assets/Dataperson.png'

export default function HeroBanner() {
  return (
    <>
    <section className="hero-banner-wrapper">
     <div className='hero-banner-text-content'>
        <div className='hero-banner-text-align'>
         <h1 className='hero-banner-text'>Design.</h1>
         <h1 className='hero-banner-text'>Utvikle.</h1>
         <h1 className='hero-banner-text'>Optimaliser.</h1>
        </div>
     </div>
       <img className='hero-banner-img' src={heroImg}/>
    { <div className='hero-banner-blur-effekt'></div> }
    </section>
    <section className='hero-section-wrapper'>

        <h2 className='hero-section-title'>Velkommen til Xinit</h2>
        <div className='hero-section-services-list'>
        </div>
        <div className='hero-section-text-wrapper'>
          <p className='hero-section-text'>Xinit utvikler programvare og apper som forvandler ideer til virkelighet. Uansett bransje, uansett behov - vi skaper skreddersydde løsninger som driver virksomheter fremover. Vi tar digitale visjoner til nye høyder.</p>
        </div>
            
        {/*<ul className='services-list'>
          <li>
            <div className='services-title-wrapper'>
              <p className='services-title'>1. Skreddersydd App-utvikling</p>
            </div>
            <div className='services-text-wrapper'>
              <p className='services-text'>Lage tilpassede apper basert på kundens krav, inkludert mobil-, web- eller plattform-uavhengige applikasjoner.</p>
            </div>
          </li>
          <li>
          <div className='services-title-wrapper'>
              <p className='services-title'>2. App-konsultasjon</p>
            </div>
            <div className='services-text-wrapper'>
              <p className='services-text'>Tilby strategisk rådgivning om app-utvikling, inkludert markedsanalyse, teknologivalg og brukeropplevelse.</p>
            </div>
          </li>
          <li>
          <div className='services-title-wrapper'>
              <p className='services-title'>3. UI/UX Design</p>
            </div>
            <div className='services-text-wrapper'>
              <p className='services-text'>Tilby designtjenester for å lage intuitive og engasjerende brukergrensesnitt og opplevelser.</p>
            </div>
          </li>
          <li>
          <div className='services-title-wrapper'>
              <p className='services-title'>4. App-vedlikehold og Support</p>
            </div>
            <div className='services-text-wrapper'>
              <p className='services-text'>Løpende støtte for å fikse feil, oppdatere funksjoner og sikre kompatibilitet med nye enheter og operativsystemer.</p>
            </div>
          </li>
          <li>
          <div className='services-title-wrapper'>
              <p className='services-title'>5. Kvalitetssikring og Testing</p>
            </div>
            <div className='services-text-wrapper'>
              <p className='services-text'>Utføre grundig testing for å sikre at appen er feilfri og fungerer godt på ulike enheter og plattformer.</p>
            </div>
          </li>
          <li>
          <div className='services-title-wrapper'>
              <p className='services-title'>6. App-markedsføring og Promotering</p>
            </div>
            <div className='services-text-wrapper'>
              <p className='services-text'>Hjelpe med strategier for å markedsføre appen, inkludert SEO, sosiale medier-kampanjer og brukeranskaffelsesstrategier.</p>
            </div>
          </li>
          <li>
          <div className='services-title-wrapper'>
              <p className='services-title'>7. Integrasjonstjeneste</p>
            </div>
            <div className='services-text-wrapper'>
              <p className='services-text'>Integrere appen med andre systemer eller tjenester, som betalingsportaler, tredjeparts-APIer eller eksisterende databaser.</p>
            </div>
          </li>
          <li>
          <div className='services-title-wrapper'>
              <p className='services-title'>8. Analyse og Rapportering</p>
            </div>
            <div className='services-text-wrapper'>
              <p className='services-text'>Implementere og analysere data for å gi innsikt i appens ytelse, brukeradferd og forbedringsmuligheter.</p>
            </div>
          </li>
          <li>
          <div className='services-title-wrapper'>
              <p className='services-title'>9. Cloud-tjenester og Hosting</p>
            </div>
            <div className='services-text-wrapper'>
              <p className='services-text'>Tilby sky-løsninger for app-hosting, datalagring og skalerbarhet.</p>
            </div>
          </li>
          <li>
          <div className='services-title-wrapper'>
              <p className='services-title'>10. Opplæring og Dokumentasjon</p>
            </div>
            <div className='services-text-wrapper'>
              <p className='services-text'>Tilby opplæring til kunder om hvordan de bruker og administrerer appen, samt lage brukerhåndbøker eller dokumentasjon.</p>
            </div>
          </li>
          <li>
          <div className='services-title-wrapper'>
              <p className='services-title'>11. Prototype og MVP-utvikling</p>
            </div>
            <div className='services-text-wrapper'>
              <p className='services-text'>Utvikle prototyper eller minimums levedyktige produkter (MVP-er) for å hjelpe kunder med å teste ideene sine før fullskala utvikling.</p>
            </div>
          </li>
          <li>
          <div className='services-title-wrapper'>
              <p className='services-title'>12. App-monetisering Strategier</p>
            </div>
            <div className='services-text-wrapper'>
              <p className='services-text'>Rådgive om strategier for å tjene penger på appen, som kjøp i appen, abonnementer eller annonseplasseringer.</p>
            </div>
          </li>
          <li>
          <div className='services-title-wrapper'>
              <p className='services-title'>13. Overholdelse og Sikkerhetstjenester</p>
            </div>
            <div className='services-text-wrapper'>
              <p className='services-text'>Sørge for at appen overholder lovkrav og er sikker mot potensielle trusler.</p>
            </div>
          </li>
          <li>
          <div className='services-title-wrapper'>
              <p className='services-title'>14. Lokalisering og Internasjonalisering</p>
            </div>
            <div className='services-text-wrapper'>
              <p className='services-text'>Tilpasse appen for ulike språk og regioner for å nå et globalt publikum.</p>
            </div>
          </li>
          <li style={{ borderBottom: "2px solid var(--primary-color-dim)", paddingBottom: "30px"}}>
          <div className='services-title-wrapper'>
              <p className='services-title'>15. Kundesupporttjenester</p>
            </div>
            <div className='services-text-wrapper'>
              <p className='services-text'>Sette opp og administrere kundesupportkanaler for å hjelpe brukere med eventuelle problemer de måtte støte på.</p>
            </div>
          </li>
        </ul>*/}
    </section>
    </>
  );
}
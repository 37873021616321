import React from 'react';
import './Introduction.css';

const intro = [
  {
    title: 'Kontakt oss!',
    message: 'Har du et spørsmål eller henvendelse? Du kan kontakte oss nedenfor. '
  }
]

export default function ContactIntro() {
  return (
    <section className="contact__intro-list">
      {intro.map((item, index) => (
        <div key={index} className='contact__intro-area'>
          <h2 className='contact__title'>{item.title}</h2>
          <div className="contact__text-wrapper">
              <p className="contact__intro-text">{item.message}</p>
            </div>
          </div>
      ))}
    </section>
  );
}
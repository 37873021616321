import React from "react"
import XaminProduct from "../components/products/xaminProduct"
import Introduction from "../components/introSections/companyIntro"
import EmployeeCard from "../components/cards/employeeCard/employeeCard"
import HeroSection from "../components/introSections/hero-section"

export default function Homepage(){

  return (
    <div className='main'>
      <HeroSection/>
      <Introduction/>
      <EmployeeCard/>
    </div>
  )
}
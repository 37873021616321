import React from "react"
import XaminProduct from "../components/products/xaminProduct"
import RotatingSquare from "../effects/blurEffectBigBlob"

export default function Products(){

  return (
    <div className="main">
      <XaminProduct/>
    </div>
  )
}
import React from "react"


export default function Error(){

  return (
    <div className='main'>
        <p className="url-adress-error-message">404</p>
        <p className="url-adress-error-message">Siden finnes ikke.</p>
    </div>
  )
}